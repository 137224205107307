import { render, staticRenderFns } from "./Tip_produse_rapide_dialog.vue?vue&type=template&id=154b0194&scoped=true&"
import script from "./Tip_produse_rapide_dialog.vue?vue&type=script&lang=js&"
export * from "./Tip_produse_rapide_dialog.vue?vue&type=script&lang=js&"
import style0 from "./Tip_produse_rapide_dialog.vue?vue&type=style&index=0&id=154b0194&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "154b0194",
  null
  
)

export default component.exports